export default class RFIDGenerator {
  constructor (skus) {
    this.reserved = '000'
    this.limitRound = 4096
    this.paddingChar = '0'

    this.skus = skus
  }

  toHex (value, padding) {
    return value.toString(16).padStart(padding, this.paddingChar)
  }

  // eslint-disable-next-line class-methods-use-this
  delay (ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  async* generate (sku, amount) {
    const aliasHex = this.toHex(sku.aliasId, 7)
    for (let i = 0; i < amount; i++) {
      if (i > 0 && i % this.limitRound === 0) {
        // eslint-disable-next-line no-await-in-loop
        await this.delay(10)
      }

      const timestamps = this.toHex(Date.now(), 11)
      const round = this.toHex(i % this.limitRound, 3)

      yield {
        rfid: `${this.reserved}${aliasHex}${timestamps}${round}`.toUpperCase(),
        code: sku.code,
        color: sku.color,
        size: sku.size,
        collection: sku.collection,
        url: sku.url,
        consumerType: sku.consumerType,
        productionDate: sku.productionDate,
        price: sku.price
      }
    }
  }

  async generateWithBatch (batchSize = 10000) {
    const batches = []

    for (const sku of this.skus) {
      const generated = this.generate(sku, sku.amount)
      let remaining = sku.amount

      while (remaining > 0) {
        const currentSize = Math.min(batchSize, remaining)
        const batch = []

        // eslint-disable-next-line no-await-in-loop
        for await (const item of generated) {
          batch.push(item)

          if (batch.length === currentSize) {
            break
          }
        }

        batches.push(...batch)
        remaining -= currentSize
      }
    }

    return batches
  }
}
